import React, { useState } from 'react';

import useStore from '../../stores';
import { API_BASE_PATH } from '../../config/api';

import PageContainer from '../../components/Page/PageContainer';
import Table from '../../components/Table/index';
import PageHeader from '../../components/Page/PageHeader';
import moment from 'moment-timezone';
import X from '../../assets/iconography/x.svg';

const columns = [
  {
    Header: 'Order Date',
    accessor: row =>
      moment
        .tz(moment.utc(row.created_at), moment.tz.guess())
        .format('YYYY-MM-DD hh:mm A'),
    Cell: ({ value }) => <div className='text-sm'>{value}</div>,
  },
  {
    Header: 'ID',
    accessor: row => row.id,
  },
  {
    Header: 'Partner',
    accessor: row => row.partner?.name,
  },
  {
    Header: 'Product',
    accessor: row => row.kits[0]?.product?.name,
  },
  {
    Header: 'Order Info',
    accessor: row => row.partner_request_body,
    Cell: ({ value }) => (
      <div className='text-sm'>
        <ul>
          <li>
            {value.first_name} {value.last_name}
          </li>
          <li>{value.email}</li>
          <li>
            Submitted by:{' '}
            {value.purchaser_email ? value.purchaser_email : 'API'}
          </li>
        </ul>
      </div>
    ),
  },
  {
    Header: 'Kit Id',
    accessor: row => row,
    Cell: ({ value }) =>
      value.fulfillments_kits
        ?.filter(fk => fk.kit_id)
        .map(fk => fk.kit_id)
        .join(','),
  },
  {
    Header: 'Invoice ID',
    accessor: row => row,
    Cell: ({ value }) =>
      value.partner_invoice_id ? (
        value.partner_invoice_id
      ) : (
        <UpdatePartnerInvoice id={value.id} status={value.status} />
      ),
  },
];

const UpdatePartnerInvoice = ({ id, status }) => {
  const token = useStore(state => state.admin.token);
  const [addInvoiceOverlay, setAddInvoiceOverlay] = useState(false);
  const [params, setParams] = useState({});

  const updateFulfillment = async (id, partner_invoice_id, status) => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/fulfillments/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ partner_invoice_id, status }),
    });
    if (res.status === 200) {
      window.location.reload();
    }
    return res.body;
  };

  return (
    <div>
      {addInvoiceOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>Add Invoice Id</span>
                </div>
                <div
                  onClick={() => setAddInvoiceOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-6 px-4'>
                <span className='text-sm'>Add invoice id for order {id}</span>
              </div>
            </div>
            <input
              placeholder='invoiceId123'
              value={params.partner_invoice_id}
              onChange={e =>
                setParams(prevState => ({
                  ...prevState,
                  partner_invoice_id: e.target.value,
                }))
              }
              className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
            />
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setAddInvoiceOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  updateFulfillment(id, params.partner_invoice_id, status)
                }
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Add Invoice Id
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <button
        className='text-sm text-green-300 underline hover:text-green-400'
        onClick={() => setAddInvoiceOverlay(true)}
      >
        Add
      </button>
    </div>
  );
};

export default function PartnerInvoices() {
  return (
    <PageContainer>
      <div className='flex justify-between'>
        <PageHeader title={'Partner Invoices'} />
      </div>
      <Table
        type={'fulfillments'}
        route={'fulfillments'}
        columns={columns}
        defaultFilter={['not yet invoiced']}
        filterType='partner_invoice_id'
        filters={['not yet invoiced', 'invoiced']}
        searchable={true}
        onRowClick={() => {
          return;
        }}
      />
    </PageContainer>
  );
}
