import { useState, useEffect } from 'react';
import { NavLink, Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import { consultStatuses, consultTypes } from '../../lib/constants';
import X from '../../assets/iconography/x.svg';
import { checkValidDates } from '../../helpers';
import { consultCreditTableColumns } from '../MainAccount/helper';
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import Table from '../../components/Table';

export default function Consult() {
  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [params, setParams] = useState({ status: 'scheduled' });
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [consultAgents, setConsultAgents] = useState([]);
  const [consultAgentFetching, setConsultAgentFetching] = useState(false);

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const dirtyFields = formState.dirtyFields;

  const getConsult = async () => {
    setFetching(true);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/consults/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const { consult } = await res.json();
      consult.scheduled_dt = consult.scheduled_dt
        ? moment
            .unix(consult.scheduled_dt)
            .tz('US/Central')
            .format('YYYY-MM-DDTHH:mm')
        : null;

      consult.has_mainaccount_id = Boolean(consult.mainaccount_id);
      consult.has_practitioner_id = Boolean(consult.practitioner_id);

      setParams(consult);
      setFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setFetching(false);
    }
  };
  const getConsultAgents = async () => {
    setConsultAgentFetching(true);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/consult-agents`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const { consultAgents } = await res.json();

      setConsultAgents(consultAgents);
      setConsultAgentFetching(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
      setConsultAgentFetching(false);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
      setConsultAgentFetching(false);
    }
  };
  const updateConsult = async () => {
    setErrors([]);
    setUpdated(false);

    if (!params.type) {
      setErrors(['Type is required']);
      return;
    }
    if (!params.payment) {
      setErrors(['Amount Paid is required']);
      return;
    }

    const scheduled_dt = moment.tz(params.scheduled_dt, 'US/Central').unix();
    if (!scheduled_dt) {
      setErrors(['Invalid scheduled_dt format (YYYY-MM-DD HH:mm a)']);
      return;
    }

    if (
      (!params.kit_id || params.kit_id.trim().length === 0) &&
      (params.type === 'coaching_programs' ||
        params.type === 'results_review_customer')
    ) {
      setErrors(['Kit ID is required for this type']);
      return;
    }

    const updateData = {
      mainaccount_id:
        params.mainaccount_id?.length > 0 ? params.mainaccount_id : null,
      email: params.email?.length > 0 ? params.email : null,
      scheduled_dt,
      payment: params.payment?.length > 0 ? params.payment : null,
      agent: params.agent,
      kit_id: params.kit_id?.length > 0 ? params.kit_id : null,
      comments: params.comments,
      agent_notes: params.agent_notes,
      completed_dt:
        params.completed_dt?.length > 0 ? params.completed_dt : null,
      status: params.status,
      type: params.type,
      tinyaccount_id: params.tinyaccount_id,
      consult_agent_id: params.consult_agent_id,
    };

    if (updateData.status === 'completed' && !updateData.completed_dt) {
      updateData.completed_dt = moment().format('YYYY-MM-DD');
    }

    try {
      checkValidDates(updateData, ['completed_dt']);
    } catch (err) {
      setErrors([err.message]);
      return;
    }

    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/consults/${id === 'new' ? '' : id}`,
      {
        method: id === 'new' ? 'POST' : 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      },
    );
    if (res.status === 200) {
      const { consult } = await res.json();
      if (id === 'new') {
        history.push(`/consults/${consult.id}`);
      } else {
        getConsult();
      }
      reset();
      setUpdated(true);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      if (Array.isArray(error)) {
        setErrors(prevArray => [...prevArray, ...error]);
      } else {
        setErrors(prevArray => [...prevArray, error]);
      }
    }
  };
  const deleteConsult = async () => {
    setErrors([]);
    setUpdated(false);

    const scheduled_dt = moment.tz(params.scheduled_dt, 'US/Central').unix();
    if (!scheduled_dt) {
      setErrors(['Invalid scheduled_dt format (YYYY-MM-DD HH:mm a)']);
      return;
    }

    const updateData = {
      scheduled_dt,
      status: params.status,
      deleted_dt: moment().format('YYYY-MM-DD'),
      consult_agent_id: params.consult_agent_id,
    };

    try {
      checkValidDates(updateData, ['deleted_dt']);
    } catch (err) {
      setErrors([err.message]);
      return;
    }

    const res = await fetch(`${API_BASE_PATH}/ops/admins/consults/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    });
    if (res.status === 200) {
      setDeleteOverlay(false);
      setUpdated(true);
      window.scrollTo(0, 0);
      await setRoute('consults');
      history.push('/consults');
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  useEffect(() => {
    setRoute('consults');
    if (id !== 'new') {
      getConsult();
    }
    getConsultAgents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const agentList = consultAgents.sort();

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      {deleteOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>
                    Delete Consult {id}?
                  </span>
                </div>
                <div
                  onClick={() => setDeleteOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-6 px-4'>
                <span className='text-sm'>
                  Are you sure you want to delete the consult{' '}
                  <span className='font-semibold'>{id}</span>? This can’t be
                  undone.
                </span>
              </div>
            </div>
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setDeleteOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() => deleteConsult()}
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='flex justify-between'>
            <div className='mt-0 flex-auto self-center'>
              <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                Consult {id}
              </h1>
            </div>
            <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
              <div className='ml-2 lg:ml-4 mt-0 flex'>
                <button
                  type='submit'
                  onClick={handleSubmit(updateConsult)}
                  disabled={fetching}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className='mb-3'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Consult {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this consult:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-1 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Consult information
                        </h2>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Main Account ID
                          </label>
                          {!params.has_mainaccount_id ? (
                            <TextInput
                              register={{
                                ...register('mainaccount_id', {
                                  onChange: e =>
                                    setParams(prevState => ({
                                      ...prevState,
                                      mainaccount_id: e.target.value,
                                    })),
                                }),
                              }}
                              placeholder=''
                              value={params.mainaccount_id}
                              isDirty={dirtyFields.mainaccount_id}
                            />
                          ) : (
                            <NavLink
                              to={`/mainaccounts/${params.mainaccount_id}`}
                              className='flex-auto relative w-full m-0 py-2 text-sm hover:text-purple-300'
                            >
                              {params.mainaccount_id}
                            </NavLink>
                          )}
                        </div>
                      </div>

                      {id !== 'new' ? (
                        <>
                          <div className='flex-1 max-w-full mt-4'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Main Account First name
                              </label>
                              <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                                {params.mainaccount
                                  ? params.mainaccount.first_name
                                  : 'N/A'}
                              </span>
                            </div>
                          </div>
                          <div className='flex-1 max-w-full mt-4'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Main Account Last name
                              </label>
                              <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                                {params.mainaccount
                                  ? params.mainaccount.last_name
                                  : 'N/A'}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <hr></hr>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Consult Email
                          </label>
                          {!params.calendly_id ? (
                            <TextInput
                              register={{
                                ...register('email', {
                                  onChange: e =>
                                    setParams(prevState => ({
                                      ...prevState,
                                      email: e.target.value,
                                    })),
                                }),
                              }}
                              placeholder=''
                              value={params.email}
                              isDirty={dirtyFields.email}
                            />
                          ) : (
                            <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                              {params.email}
                            </span>
                          )}
                        </div>
                      </div>

                      {params.calendly_id ? (
                        <>
                          <div className='flex-1 max-w-full mt-4'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Calendly ID
                              </label>
                              <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                                {params.calendly_id}
                              </span>
                            </div>
                          </div>

                          <div className='flex-1 max-w-full mt-4'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Calendly Reschedule URL
                              </label>
                              <div className='flex-auto relative w-full m-0 py-2 rounded-md text-sm cursor-not-allowed'>
                                <Link
                                  to={{
                                    pathname: params.calendly_reschedule_url,
                                  }}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='underline text-blue-600'
                                >
                                  {params.calendly_reschedule_url}
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className='flex-1 max-w-full mt-4'>
                            <div className='flex flex-col relative'>
                              <label className='mb-1 p-0 text-sm'>
                                Calendly Cancel URL
                              </label>
                              <div className='flex-auto relative w-full m-0 py-2 rounded-md text-sm cursor-not-allowed'>
                                <Link
                                  to={{
                                    pathname: params.calendly_cancel_url,
                                  }}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='underline text-blue-600'
                                >
                                  {params.calendly_cancel_url}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}

                      <SelectInput
                        title='Type'
                        value={params.type}
                        register={{
                          ...register('type', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                type: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.type}
                        options={consultTypes.map(type => ({
                          value: type,
                          title: type,
                        }))}
                      />

                      <SelectInput
                        title='Status'
                        value={params.status}
                        register={{
                          ...register('status', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                status: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.status}
                        options={consultStatuses.map(status => ({
                          value: status,
                          title: status,
                        }))}
                      />

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Scheduled date (CST)
                          </label>
                          <input
                            type='datetime-local'
                            placeholder='Must be in "YYYY-MM-DD HH:mm a" format'
                            value={params.scheduled_dt}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                scheduled_dt: e.target.value,
                              }))
                            }
                            className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          />
                        </div>
                      </div>
                      <TextInput
                        type='datetime-local'
                        title='Scheduled date (CST)'
                        register={{
                          ...register('scheduled_dt', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                scheduled_dt: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='Must be in "YYYY-MM-DD HH:mm a" format'
                        value={params.scheduled_dt}
                        isDirty={dirtyFields.scheduled_dt}
                      />
                      <TextInput
                        title='Amount paid'
                        register={{
                          ...register('payment', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                payment: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='Amount paid for consult'
                        value={params.payment}
                        isDirty={dirtyFields.payment}
                      />
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Consult credit
                          </label>
                          {params.consult_credit ? (
                            <div className='flex-1 max-w-full'>
                              <div className='flex flex-col relative'>
                                <div className='w-full border-collapse'>
                                  <Table
                                    type={'consultCredits'}
                                    columns={consultCreditTableColumns}
                                    staticData={[params.consult_credit]}
                                  ></Table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            'No Consult Credit used'
                          )}
                        </div>
                      </div>

                      <SelectInput
                        title='Consult Agent'
                        value={
                          params.agent && !params.consult_agent_id
                            ? params.agent
                            : params.consult_agent_id
                        }
                        register={{
                          ...register('consult_agent_id', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                consult_agent_id: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.consult_agent_id}
                        options={
                          params.agent && !params.consult_agent_id
                            ? [{ title: params.agent, value: params.agent }]
                            : agentList.map(agent => ({
                                title: agent.email,
                                value: agent.id,
                              }))
                        }
                      />

                      {!params.mainaccount_id ? (
                        ''
                      ) : (
                        <SelectInput
                          title='Tiny Account'
                          value={params.tinyaccount_id}
                          register={{
                            ...register('tinyaccount_id', {
                              onChange: e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  tinyaccount_id: e.target.value,
                                })),
                            }),
                          }}
                          isDirty={dirtyFields.tinyaccount_id}
                          options={
                            params.mainaccount?.tinyaccounts?.map(
                              tinyaccount => ({
                                title: `${tinyaccount.first_name} ${tinyaccount.last_name}`,
                                value: tinyaccount.id,
                              }),
                            ) ?? []
                          }
                        />
                      )}
                      <TextInput
                        title='Kit ID'
                        register={{
                          ...register('kit_id', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                kit_id: e.target.value,
                              })),
                          }),
                        }}
                        placeholder=''
                        value={params.kit_id}
                        isDirty={dirtyFields.kit_id}
                      />

                      <TextInput
                        title='Comments'
                        type='textarea'
                        register={{
                          ...register('comments', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                comments: e.target.value,
                              })),
                          }),
                        }}
                        placeholder=''
                        value={params.comments}
                        isDirty={dirtyFields.comments}
                      />

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Agent Notes
                            <Link
                              to={{
                                pathname:
                                  'https://tinyhealth.atlassian.net/wiki/x/AwDKDg',
                              }}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='ml-[0.5rem] text-red-600 hover:underline'
                            >
                              (Internal) Template Link
                            </Link>
                          </label>
                          <TextInput
                            type='textarea'
                            register={{
                              ...register('agent_notes', {
                                onChange: e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    agent_notes: e.target.value,
                                  })),
                              }),
                            }}
                            placeholder=''
                            value={params.agent_notes}
                            isDirty={dirtyFields.agent_notes}
                          />
                        </div>
                      </div>

                      <TextInput
                        title='Completed date'
                        register={{
                          ...register('completed_dt', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                completed_dt: e.target.value,
                              })),
                          }),
                        }}
                        placeholder=''
                        value={params.completed_dt}
                        isDirty={dirtyFields.completed_dt}
                      />

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Post-Consult Follow-Up email sent on
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm text-gray-500'>
                            {params.date_post_consult_follow_up ??
                              'The email was not sent'}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Consult Note
                          </label>
                          <div className='px-4 lg:px-8 pt-4 pb-8'>
                            <div className='flex-1 max-w-full'>
                              <div className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'>
                                <NavLink to={`/consults/${id}/note`}>
                                  <span
                                    className={`flex text-sm text-center rounded-2xl p-2 bg-${
                                      params.consult_note?.status === 'draft'
                                        ? 'yellow'
                                        : params.consult_note?.status ===
                                          'published'
                                        ? 'green'
                                        : 'red'
                                    }-100 mb-1 w-24`}
                                  >
                                    {params.consult_note
                                      ? params.consult_note.status
                                      : 'missing'}
                                  </span>
                                  <span className='flex mb-1 p-0 text-sm'>
                                    {params.consult_note
                                      ? `Updated ${params.consult_note?.updated_at}`
                                      : ''}
                                  </span>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-2 flex-1 max-w-full'>
                        <div className='flex'>
                          <input
                            className='cursor-pointer w-4'
                            type='checkbox'
                            disabled={
                              params.consult_note?.status === 'published'
                            }
                            checked={params.auto_publish}
                            onChange={e =>
                              setParams(prevState => ({
                                ...prevState,
                                auto_publish: e.target.checked,
                              }))
                            }
                          />
                          <label className='ml-2 pt-1 text-sm'>
                            Auto Publish
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {id !== 'new' && (
              <div className='mb-8'>
                <button
                  onClick={() => setDeleteOverlay(true)}
                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-pink-50 rounded-md border border-solid border-pink-900 text-pink-900 transition-all duration-200 ease-in-out delay-75'
                >
                  Delete consult
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
