import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderGroup, useTable } from 'react-table';
import queryString from 'query-string';

import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import updateLocalToken from '../../lib/updateLocalToken';
import { queryBuilder } from '../../lib/utils/queryBuilder';
import ErrorDisplay from '../../components/ErrorDisplay';

import TableLoading from './TableLoading';
import TableContainer from './TableContainer';
import TablePaginator from './TablePaginator';
import TableFilterTabBar from './TableFilterTabBar';

import InputSearch, {
  InputSearchPropsRef,
  FilterLabel,
  Filters,
} from '@sefirosweb/react-multiple-search';

import '../../styles/InputSearch.css';

// type MyColumns

export default function Table({
  type,
  route,
  onDataFetched,
  columns,
  defaultFilter,
  filters,
  filterType,
  filterDataFunc,
  CustomFilterComponent,
  onRowClick,
  CustomRowComponent,
  showCustomRowComponent,
  searchable,
  searchFilterLabels,
  staticData,
  disabled,
}: {
  type: string;
  route?: string;
  onDataFetched?: any;
  columns?: any;
  defaultFilter?: any;
  filters?: any;
  filterType?: any;
  filterDataFunc?: any;
  CustomFilterComponent?: any;
  onRowClick?: any;
  CustomRowComponent?: any;
  showCustomRowComponent?: any;
  searchable?: boolean;
  searchFilterLabels?: FilterLabel[];
  staticData?: any;
  disabled?: any;
}) {
  const history = useHistory();
  const location = useLocation();
  const token = useStore(state => state.admin.token || '');
  const admin = useStore(state => state.admin.admin);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [data, setData] = useState<{ [key: string]: any }[]>([]);
  const [maxPage, setMaxPage] = useState(0);

  const parsedQuery = queryString.parse(location.search);
  const pageLimit = Number(parsedQuery.pageLimit) || 50;
  const page = Number(parsedQuery.page) || 1;
  const filter = parsedQuery.filter || defaultFilter || '';
  const { searchTerm, searchTermFilter, orderBy, order } = parsedQuery;

  const [searchValue, setSearchValue] = useState(searchTerm?.toString() || '');
  const inputSearchRef = useRef<HTMLInputElement>(null);

  let parsedSearchTermFilter;
  try {
    if (searchTermFilter && searchTermFilter.length > 0) {
      parsedSearchTermFilter = JSON.parse(searchTermFilter.toString());
    }
  } catch (err) {
    //bad JSON
  }
  const [searchFilter, setSearchFilter] = useState<Filters[]>(
    parsedSearchTermFilter || [],
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/${route || type}?${queryBuilder({
        page: page - 1,
        pageLimit: pageLimit,
        filterType: filterType ?? 'status',
        filterValue: filter?.toString() || '',
        searchTerm: searchTerm?.toString() || '',
        searchTermFilter: searchTermFilter?.toString() || '',
        orderBy: orderBy?.toString() || '',
        order: order?.toString() || 'desc',
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
        },
      },
    );
    if (res.status === 200) {
      const extended_token = res.headers.get('X-Access-Token');
      //if new access_token in response header, store in state
      if (extended_token) {
        updateLocalToken(extended_token);
        setAdmin(admin, extended_token);
      }

      const { [type]: data, maxPage } = await res.json();
      setData(data);

      if (onDataFetched) {
        onDataFetched(data);
      }

      setMaxPage(maxPage);
      setIsLoading(false);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setError(
        'There was a problem loading your information, please try again later or contact support.',
      );
      setIsLoading(false);
    } else {
      const responseData = await res.json();
      if (responseData.error) {
        const { error } = responseData;
        setError(error);
        setIsLoading(false);
      } else {
        setError('Unknown error');
        setIsLoading(false);
      }
    }
  }, [route, location.search]);

  useEffect(() => {
    if (typeof columns === 'function') {
      setFilteredColumns(columns(filter));
    }

    if (staticData) {
      setData(staticData);
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [fetchData, staticData]);

  useEffect(() => {
    if (!isLoading) {
      handleSearch(new Event(''));
    }
  }, [searchFilter]);

  const setPage = (page: number) => {
    updateQueryParams({
      page: page <= 1 ? undefined : page,
    });
  };

  const setFilter = (filter: string) => {
    updateQueryParams({
      filter: filter !== '' ? filter : undefined,
      page: undefined,
      orderBy: undefined,
      order: undefined,
    });
  };

  const setOrderBy = (orderBy?: string, order?: string) => {
    updateQueryParams({
      orderBy: orderBy ? orderBy : undefined,
      order: order ? order : undefined,
    });
  };

  const updateQueryParams = (params?: object) => {
    const newQueryParams = queryString.stringify({
      ...queryString.parse(location.search),
      ...params,
    });

    history.replace({
      pathname: history.location.pathname,
      search: newQueryParams,
    });
  };

  const navigate = (row: any, openNew?: boolean) => {
    if (openNew) {
      window.open(`/${type}/${row.original.id}`, '_blank');
    } else {
      history.push(`/${type}/${row.original.id}`);
    }
  };

  let filteredData = data;
  if (filterDataFunc && filteredData) {
    filteredData = data.filter(filterDataFunc);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable({ columns: filteredColumns, data: filteredData });

  const handleSortChange = (columnId: any) => {
    if (columnId !== orderBy) {
      setOrderBy(columnId, 'desc');
    } else if (order === 'desc') {
      setOrderBy(columnId, 'asc');
    } else {
      setOrderBy();
    }
  };
  const handleRowClick = (row: any, openNew?: boolean) => {
    if (disabled && disabled(filter)) {
      return;
    }

    if (onRowClick) {
      onRowClick(row);
    } else {
      navigate(row, openNew);
    }
  };
  const handleSearch = (event: any) => {
    event.preventDefault();

    let searchTerm = undefined;
    let searchTermFilter = undefined;

    if (searchFilterLabels) {
      if (searchFilter.length > 0) {
        const searchTermFilterTrim = searchFilter.map(filter => {
          return {
            ...filter,
            text: filter.text.trim(),
          };
        });
        searchTermFilter = JSON.stringify(searchTermFilterTrim);
      }
    } else if (searchValue !== '') {
      searchTerm = searchValue.trim();
    }

    updateQueryParams({
      searchTerm,
      searchTermFilter,
      page: undefined,
    });
  };

  const removeRow = (row_index: number) => {
    const newData = [...data];
    if (newData[row_index]) {
      newData.splice(row_index, 1);
      setData(newData);
    }
  };

  const updateRow = (row_index: number, row_data: { [key: string]: any }) => {
    const newData = [...data];
    if (newData[row_index]) {
      newData[row_index] = row_data;
      setData(newData);
    }
  };

  const clearSearch = () => {
    if (inputSearchRef?.current) {
      inputSearchRef.current.value = '';
      inputSearchRef.current.focus();
    }

    setSearchValue('');
    setSearchFilter([]);
  };

  return (
    <TableContainer>
      {filters && (
        <TableFilterTabBar
          filters={filters}
          filter={filter}
          setFilter={setFilter}
        />
      )}

      {searchable && (
        <div className='flex-1 max-w-full my-4 p-4'>
          <div className='flex flex-col relative'>
            <label className='mb-1 p-0 text-sm'>Search</label>
            <div className='flex flex-row relative'>
              {searchFilterLabels && searchFilterLabels.length > 0 ? (
                <div className='inputSearch flex-auto relative m-0 mr-10 w-72 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'>
                  <InputSearch
                    filters={searchFilter}
                    setFilters={setSearchFilter}
                    filterLabels={searchFilterLabels}
                  />
                </div>
              ) : (
                <input
                  ref={inputSearchRef}
                  type='text'
                  id='search'
                  className='flex-auto relative m-0 mr-10 w-72 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                  placeholder='Search'
                  defaultValue={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSearch(e);
                    }
                  }}
                />
              )}

              <button
                type='submit'
                onClick={handleSearch}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
              >
                Search
              </button>
            </div>
          </div>
        </div>
      )}

      {CustomFilterComponent}

      {error ? (
        <ErrorDisplay error={error} />
      ) : isLoading ? (
        <TableLoading />
      ) : data ? (
        <>
          <TablePaginator page={page} maxPage={maxPage} setPage={setPage} />
          <div className='bg-white rounded-md w-full'>
            <div className='w-full border-collapse overflow-x-auto'>
              <table
                className='border-collapse w-full text-left'
                {...getTableProps()}
              >
                <thead>
                  {headerGroups.map((headerGroup, header_index) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={header_index}
                    >
                      {headerGroup.headers.map((column, column_index) => (
                        <th
                          className={`py-2 px-3 border-none border-gray-200 bg-gray-100 text-xs text-gray-600 uppercase tracking-wider ${
                            //eslint-disable-next-line
                            //@ts-ignore
                            column.disable_sort ? '' : 'cursor-pointer'
                          } ${
                            column_index === 0
                              ? 'sticky left-0 z-10 border'
                              : ''
                          }`}
                          onClick={() => {
                            //eslint-disable-next-line
                            //@ts-ignore
                            if (!column.disable_sort) {
                              handleSortChange(column.id);
                            }
                          }}
                          {...column.getHeaderProps()}
                          key={column_index}
                        >
                          {column.render('Header')}
                          {orderBy === column.id ? (
                            <>{order === 'desc' ? '↓' : '↑'}</>
                          ) : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows.map((row, row_index) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={row_index}>
                        {CustomRowComponent &&
                        showCustomRowComponent(filter) ? (
                          <tr
                            className={`${
                              disabled && disabled(filter)
                                ? ''
                                : 'cursor-pointer'
                            }`}
                            onClick={() => handleRowClick(row, false)}
                            {...row.getRowProps()}
                            key={row_index}
                          >
                            <td colSpan={visibleColumns.length}>
                              <CustomRowComponent
                                row={row}
                                row_index={row_index}
                                filter={filter}
                                setFilter={setFilter}
                                updateRow={updateRow}
                                removeRow={removeRow}
                                clearSearch={clearSearch}
                              />
                            </td>
                          </tr>
                        ) : (
                          <tr
                            className={`${
                              disabled && disabled(filter)
                                ? ''
                                : 'cursor-pointer'
                            } group`}
                            onClick={() => handleRowClick(row, false)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell, cell_index) => {
                              return (
                                <td
                                  className={`py-2 px-3 border-b border-gray-200 bg-white ${
                                    cell_index === 0
                                      ? 'sticky left-0 z-10 border-r'
                                      : ''
                                  } group-hover:bg-pink-50`}
                                  {...cell.getCellProps()}
                                  key={cell_index}
                                >
                                  {cell_index === 0 ? (
                                    <span
                                      className={`${
                                        disabled && disabled(filter)
                                          ? ''
                                          : 'hover:underline'
                                      }`}
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleRowClick(row, true);
                                      }}
                                    >
                                      {cell.render('Cell')}
                                    </span>
                                  ) : (
                                    cell.render('Cell')
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <TablePaginator page={page} maxPage={maxPage} setPage={setPage} />
        </>
      ) : null}
    </TableContainer>
  );
}
