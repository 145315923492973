import c from 'classnames';
const FilterTab = ({ value, selected, setFilter, classNamesInner }) => {
  const classnames =
    'font-medium m-0 py-3 px-2 border-none text-sm leading-8 relative w-full min-w-full outline-none text-center whitespace-nowrap cursor-pointer';
  return (
    <li className='flex mx-2'>
      <div
        onClick={() => setFilter(value)}
        className={c(
          classnames,
          selected
            ? 'tab-underline-selected text-black '
            : 'tab-underline text-gray-700 hover:text-black',
          classNamesInner,
        )}
      >
        {value.split('_').join(' ').toUpperCase()}
      </div>
    </li>
  );
};

const TableFilterTabBar = ({
  filters,
  filter,
  setFilter,
  classNamesInner = '',
}) => {
  return (
    <div className='flex'>
      <div className='flex-auto overflow-hidden'>
        <div className='border-b border-solid border-gray-300 px-3'>
          <ul className='flex m-0 p-0 list-none overflow-x-scroll'>
            {filters.map((f, index) => (
              <FilterTab
                key={index}
                value={f}
                selected={f === filter}
                setFilter={setFilter}
                classNamesInner={classNamesInner}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TableFilterTabBar;
